import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import "./layout.css"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, darkmode, location }) => {
  return (
    <>
      {location.pathname === "/" ? null : (
        <Header darkmode={darkmode} location={location} />
      )}
      <LayoutContainer>
        <main>{children}</main>
      </LayoutContainer>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// Styles
const LayoutContainer = styled.div`
  max-width: 100vw;
  margin: 0 auto;

  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`

export default Layout
